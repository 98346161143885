import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Iconify from '../../components/Iconify';

const ItemMenu = ({ menuTitle = 'Ntn File', icon = 'logos:createjs', onClickHandler = () => {}, data = [] }) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>{' '}
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {data.length > 0 ? (
          data.map(({ menuTitle, icon = 'logos:createjs', onClickHandler = () => {} }, index) => (
            <MenuItem key={index} sx={{ color: 'text.secondary' }}>
              <ListItemIcon>
                <Iconify icon={icon} width={24} height={24} />
              </ListItemIcon>
              <ListItemText
                primary={menuTitle}
                primaryTypographyProps={{ variant: 'body2' }}
                onClick={onClickHandler}
              />
            </MenuItem>
          ))
        ) : (
          <MenuItem sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Iconify icon={icon} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary={menuTitle} primaryTypographyProps={{ variant: 'body2' }} onClick={onClickHandler} />
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default ItemMenu;
