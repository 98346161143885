import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

const TextEditor = ({ editorRef, value = '<p>This is the initial content of the editor.</p>' }) => {
  console.log(editorRef);
  return (
    <Editor
      apiKey="e2uvgi4rxyr1tj0w7cv0ll8a42as237ke2p3wf3ndzh65mnz"
      onInit={(evt, editor) => (editorRef.current = editor)}
      initialValue={value}
      init={{
        height: 500,
        menubar: false,
        plugins: [
          'advlist',
          'autolink',
          'lists',
          'link',
          'image',
          'charmap',
          'preview',
          'anchor',
          'searchreplace',
          'visualblocks',
          'code',
          'fullscreen',
          'insertdatetime',
          'media',
          'table',
          'code',
          'help',
          'wordcount',
        ],
        toolbar:
          'undo redo | blocks | ' +
          'bold italic forecolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'removeformat | help',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
      }}
    />
  );
};

export default TextEditor;
