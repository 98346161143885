import * as _axios from 'axios';
import environment from './environment/env';
import JWTService from './services/JWTService';

const { baseURL } = environment;
const axios = _axios.create({
  baseURL,
});

axios.interceptors.request.use((request) => {
  if (localStorage.getItem('token'))
    request.headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    };
  return request;
});

export default axios;
