import { _get, _post, _put, _delete } from './httpService';

const _createBlog = (blogData) => _post('/mbjBlogs/createBlog', blogData);

const _editBlog = (blogData, blogId) => _put(`/mbjBlogs/editBlogById/${blogId}`, blogData);

const _getSingleBlog = (blogId) => _get(`/mbjBlogs/getBlogById/${blogId}`);

const _getAllBlogs = () => _get('/mbjBlogs/getAllBlogs');

const _deleteBlog = (blogId) => _delete(`/mbjBlogs/deleteBlogById/${blogId}`);

const MbjBlogsService = {
  _createBlog,
  _editBlog,
  _getSingleBlog,
  _getAllBlogs,
  _deleteBlog,
};

export default MbjBlogsService;
