import { _get, _post, _put, _delete } from './httpService';

const _getAllInfoRequest = () => _get(`/query?isService=0`);
const _getAllServiceRequest = (type) => _get(`/query?isService=1&type=${type}`);
const QueueService = {
  _getAllInfoRequest,
  _getAllServiceRequest,
};

export default QueueService;
