import { faker } from '@faker-js/faker';
// @mui
import { Container, Grid, Typography } from '@mui/material';
// components
import Page from '../components/Page';
// sections
import { AppNewsUpdate, AppOrderTimeline, AppWidgetSummary } from '../sections/@dashboard/app';
import BlogsService from 'src/services/BlogService';
import { useEffect, useState } from 'react';
import environment from 'src/environment/env';
import { _get } from 'src/services/httpService';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const { _getAllBlogs } = BlogsService;
  const [posts, setPosts] = useState([]);
  const [dashboard, setDashboard] = useState({
    cases: 0,
    statutes: 0,
    requestedCase: 0,
    query: 0,
    ntnUsers: 0,
    libraryUsers: 0,
    totalCallAppointment: 0,
    completedCallAppointment: 0,
    totalPhysicalAppointment: 0,
    completedPhysicalAppointment: 0,
  });

  useEffect(() => {
    getAllBlogs();
    getDashboard();
  }, []);

  const getDashboard = () => {
    _get('/dashboard').then((res) => {
      console.log(res.data);
      setDashboard(res.data.data);
    });
  };

  const getAllBlogs = async () => {
    _getAllBlogs().then((res) => {
      if (res.status === 200) {
        console.log(res);
        setPosts(res.data.data);
      }
    });
  };

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back Admin
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Cases upload" total={dashboard.cases} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Statutes" total={dashboard.statutes} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Request Cases" total={dashboard.requestedCase} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Query" total={dashboard.query} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="NTN User" total={dashboard.ntnUsers} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Library User" total={dashboard.libraryUsers} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Call appointment (Completed/Total)"
              total={`${dashboard.completedCallAppointment}/${dashboard.totalCallAppointment}`}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Physical appointment (Completed/Total)"
              total={`${dashboard.completedPhysicalAppointment}/${dashboard.totalPhysicalAppointment}`}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <AppNewsUpdate
              title="News blogs"
              list={posts.map(({ id, title, file, date, short_paragraph }, index) => ({
                id: id,
                title: title,
                description: short_paragraph || '',
                image: `${environment.fileURL}${file}`,
                postedAt: date,
              }))}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
