/*  */
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import FileBase64 from 'react-file-base64';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import TextEditor from 'src/components/TextEditor';
import * as yup from 'yup';
import { COURTS, MONTHS } from '../../constants/constants';
import environment from '../../environment/env';
import CaseLawService from '../../services/CaseLawService';
import StatuteService from '../../services/StatuteService';

const EditCase = () => {
  // const courts = COURTS;
  const { _updateCase, _getCaseById } = CaseLawService;
  const { _getStatutesOnly, _getStatuteById } = StatuteService;
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id } = state;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [statutes, setStatutes] = useState([]);
  useEffect(() => {
    getStatutes();
  }, []);
  useEffect(() => {
    getCaseById(id);
  }, [statutes]);
  const uploader = useRef();
  const allowedFormates = ['pdf'];
  const [setFile, setFileError] = useState('');

  const [hasFile, setHasFile] = useState(false);
  const editorRef = useRef(null);
  const shortRef = useRef(null);

  useEffect(() => {}, []);
  const { fileURL } = environment;

  const notify = (message, type) =>
    toast(message, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      type,
    });
  const formik = useFormik({
    initialValues: {
      year_or_vol: '',
      pageNo: '',
      month: '',
      law_or_statute: '',
      law_or_statute_id: 0,
      section: '',
      section2: '',
      court: '',
      caseNo: '',
      dated: '',
      // phraseSearch: '',
      judge: '',
      lawyer: '',
      journals: '',
      appellant_or_opponent: '',
      principleOfCaseLaws: '',
      // shortParagraph: '',
      // file: '',
    },
    validationSchema: yup.object({
      year_or_vol: yup.string().required('Year or Vol is required'),
      pageNo: yup.string().required('Page No is required'),
      month: yup.string().required('Month is required'),
      law_or_statute_id: yup.string().required('Law or Statute is required'),
      section: yup.string().required('Section is required'),
      section2: yup.string().required('Section2 is required'),
      court: yup.string().required('Court is required'),
      caseNo: yup.string().required('Case No is required'),
      dated: yup.string().required('Dated is required'),
      // phraseSearch: yup.string().required('Phrase Search is required'),
      judge: yup.string().required('Judge is required'),
      lawyer: yup.string().required('Lawyer is required'),
      journals: yup.string().required('Journals is required'),
      appellant_or_opponent: yup.string().required('Appellant or Opponent is required'),
      principleOfCaseLaws: yup.string().required('Principle of Case Laws is required'),
      // shortParagraph: yup.string().required('Short Paragraph of Case Laws is required'),
    }),

    onSubmit: (values) => {
      // setFileError('');
      setIsSubmitting(true);
      // console.log(values);
      // if (!values.file) {
      //   setFileError('Please select a file');
      //   return;
      // }
      // setIsSubmitting(true);
      _updateCase(
        {
          ...values,
          shortParagraph: shortRef.current.getContent(),
          file: editorRef.current.getContent(),
        },
        id
      )
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            notify('Case updated successfully', 'success');
            setIsSubmitting(false);
            setTimeout(() => {
              navigate('/caselaws');
            }, 1000);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsSubmitting(false);

          notify(err?.response?.data?.message, 'error');
        })
        .finally(setIsSubmitting(false));
    },
  });

  const onFileUpload = (event) => {
    console.log(event.target.files[0]);
    setFileError('');
    const fileFormate = event.target.files[0].name.split('.').pop();
    console.log(fileFormate);
    const isValidFormate = allowedFormates.filter((formate) => formate === fileFormate).length;
    if (isValidFormate === 0) {
      setFileError('Please upload a pdf file');
      uploader.current.value = '';
      formik.setFieldValue('file', '');
    }
  };

  const getStatutes = () => {
    _getStatutesOnly()
      .then(async (res) => {
        if (res.status === 200) {
          console.log('kon kon sy statutes', res);
          setStatutes(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCaseById = (id) => {
    _getCaseById(id)
      .then(async (res) => {
        if (res.status === 200) {
          // console.log('Kia is me id hy', res);
          if (res.data.data.length) {
            const response = res.data.data[0];
            console.log('Ab yeh kia hy', response);
            setHasFile(true);
            await formik.setValues(response);
            const court = _.findIndex(COURTS, ['label', response.court]);
            // console.log(court);
            // formik.setFieldValue('court', COURTS[court]);
            formik.setFieldValue('court', COURTS[court].label);

            if (statutes.length) {
              const law_or_statute = _.findIndex(statutes, ['id', response.law_or_statute_id]);
              console.log(law_or_statute);
              console.log(statutes.length);
              // console.log(law_or_statute);
              await formik.setFieldValue('law_or_statute', statutes[law_or_statute]?.law_or_statute);
              console.log(statutes);
              // console.log('local_statute', local_law);
              // await formik.setFieldValue('law_or_statute', local_law);
              // c;
              // console.log('ly bhai ho gya', local_statute.data.data[0].law_or_statute);
              // await formik.setFieldValue('law_or_statute_id', resp.data[0].id);
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // useEffect(()=>{
  //   _getStatuteById()
  // })

  return (
    <Container>
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Typography sx={{ fontSize: 24, fontWeight: 'bold' }} color="text.primary" gutterBottom>
            Edit Case{' '}
          </Typography>

          <Box sx={{ flexGrow: 1 }}>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="Page No"
                    color="secondary"
                    id="pageNo"
                    type="number"
                    InputProps={{
                      inputProps: {
                        type: 'number',
                        min: 0,
                      },
                    }}
                    key="pageNo"
                    value={formik.values.pageNo}
                    onChange={formik.handleChange}
                    fullWidth
                  />
                  {formik.errors.pageNo && formik.touched.pageNo ? (
                    <p style={{ color: 'red', fontSize: 12 }}>{formik.errors.pageNo}</p>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="Year/Vol"
                    color="secondary"
                    id="year_or_vol"
                    type="number"
                    key="year_or_vol"
                    InputProps={{
                      inputProps: {
                        type: 'number',
                        min: 0,
                      },
                    }}
                    value={formik.values.year_or_vol}
                    onChange={formik.handleChange}
                    fullWidth
                  />

                  {formik.errors.year_or_vol && formik.touched.year_or_vol ? (
                    <p style={{ color: 'red', fontSize: 12 }}>{formik.errors.year_or_vol}</p>
                  ) : null}
                </Grid>
                <Grid item xs={6} md={4}>
                  <TextField
                    id="month"
                    // select
                    label="Citation Magazine"
                    color="secondary"
                    key="month"
                    value={formik.values.month}
                    onChange={(event) => {
                      formik.setFieldValue('month', event.target.value);
                    }}
                    fullWidth
                  >
                    {/* {MONTHS.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))} */}
                  </TextField>
                  {formik.errors.month && formik.touched.month ? (
                    <p style={{ color: 'red', fontSize: 12 }}>{formik.errors.month}</p>
                  ) : null}
                </Grid>
                {/* <Grid item xs={12} md={4}>
                  <TextField
                    label="Phrase Search"
                    color="secondary"
                    id="phraseSearch"
                    type="text"
                    key="phraseSearch"
                    value={formik.values.phraseSearch}
                    onChange={formik.handleChange}
                    fullWidth
                  />
                  {formik.errors.phraseSearch && formik.touched.phraseSearch ? (
                    <p style={{ color: 'red', fontSize: 12 }}>{formik.errors.phraseSearch}</p>
                  ) : null}
                </Grid> */}
                <Grid item xs={12} md={4}>
                  <TextField
                    label="Judge"
                    color="secondary"
                    id="judge"
                    type="text"
                    key="judge"
                    value={formik.values.judge}
                    onChange={formik.handleChange}
                    fullWidth
                  />
                  {formik.errors.judge && formik.touched.judge ? (
                    <p style={{ color: 'red', fontSize: 12 }}>{formik.errors.judge}</p>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="Lawyer"
                    color="secondary"
                    id="lawyer"
                    type="text"
                    key="lawyer"
                    value={formik.values.lawyer}
                    onChange={formik.handleChange}
                    fullWidth
                  />
                  {formik.errors.lawyer && formik.touched.lawyer ? (
                    <p style={{ color: 'red', fontSize: 12 }}>{formik.errors.lawyer}</p>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="Appellant or Opponent"
                    color="secondary"
                    id="appellant_or_opponent"
                    type="text"
                    key="appellant_or_opponent"
                    value={formik.values.appellant_or_opponent}
                    onChange={formik.handleChange}
                    fullWidth
                  />
                  {formik.errors.appellant_or_opponent && formik.touched.appellant_or_opponent ? (
                    <p style={{ color: 'red', fontSize: 12 }}>{formik.errors.appellant_or_opponent}</p>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="Section 1"
                    color="secondary"
                    id="section"
                    type="text"
                    key="section"
                    value={formik.values.section}
                    onChange={formik.handleChange}
                    fullWidth
                  />
                  {formik.errors.section && formik.touched.section ? (
                    <p style={{ color: 'red', fontSize: 12 }}>{formik.errors.section}</p>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="Section 2"
                    color="secondary"
                    id="section2"
                    type="text"
                    key="section2"
                    value={formik.values.section2}
                    onChange={formik.handleChange}
                    fullWidth
                  />
                  {formik.errors.section2 && formik.touched.section2 ? (
                    <p style={{ color: 'red', fontSize: 12 }}>{formik.errors.section2}</p>
                  ) : null}
                </Grid>

                <Grid item xs={12} md={4}>
                  <TextField
                    color="secondary"
                    id="dated"
                    type="date"
                    key="dated"
                    value={formik.values.dated}
                    onChange={formik.handleChange}
                    fullWidth
                  />
                  {formik.errors.dated && formik.touched.dated ? (
                    <p style={{ color: 'red', fontSize: 12 }}>{formik.errors.dated}</p>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={6}>
                  {/* <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={courts}
                    value={formik.values.court}
                    onChange={(event, newValue) => {
                      console.log(newValue);
                      formik.setFieldValue('court', newValue.label || '');
                    }}
                    renderInput={(params) => <TextField {...params} label="Court" />}
                    fullWidth
                  /> */}
                  <TextField
                    label="Select Court"
                    color="secondary"
                    id="court"
                    type="text"
                    key="court"
                    value={formik.values.court}
                    onChange={formik.handleChange}
                    fullWidth
                  />
                  {formik.errors.court && formik.touched.court ? (
                    <p style={{ color: 'red', fontSize: 12 }}>{formik.errors.court}</p>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Journals"
                    color="secondary"
                    id="journals"
                    type="text"
                    key="journals"
                    value={formik.values.journals}
                    onChange={formik.handleChange}
                    fullWidth
                  />
                  {formik.errors.journals && formik.touched.journals ? (
                    <p style={{ color: 'red', fontSize: 12 }}>{formik.errors.journals}</p>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={12}>
                  <Autocomplete
                    disablePortal
                    id="statutes"
                    options={statutes}
                    // defaultValue="abc"
                    value={formik.values.law_or_statute}
                    getOptionLabel={(option) => option?.law_or_statute || ''}
                    onChange={(event, newValue) => {
                      console.log(newValue);
                      // formik.setFieldValue('law_or_statute', newValue ? newValue.law_or_statute : '');
                      formik.setFieldValue('law_or_statute_id', newValue ? newValue.id : 0);
                    }}
                    renderInput={(params) => <TextField {...params} label="Law/Statute" />}
                    fullWidth
                  />

                  {formik.errors.law_or_statute && formik.touched.law_or_statute ? (
                    <p style={{ color: 'red', fontSize: 12 }}>{formik.errors.law_or_statute}</p>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    label="Case no"
                    color="secondary"
                    id="caseNo"
                    type="text"
                    key="caseNo"
                    value={formik.values.caseNo}
                    onChange={formik.handleChange}
                    multiline
                    fullWidth
                  />
                  {formik.errors.caseNo && formik.touched.caseNo ? (
                    <p style={{ color: 'red', fontSize: 12 }}>{formik.errors.caseNo}</p>
                  ) : null}
                </Grid>

                <Grid item xs={12} md={12}>
                  <TextField
                    label="Principle of Case Laws"
                    color="secondary"
                    id="principleOfCaseLaws"
                    type="text"
                    key="principleOfCaseLaws"
                    rows={3}
                    value={formik.values.principleOfCaseLaws}
                    onChange={formik.handleChange}
                    multiline
                    fullWidth
                  />
                  {formik.errors.principleOfCaseLaws && formik.touched.principleOfCaseLaws ? (
                    <p style={{ color: 'red', fontSize: 12 }}>{formik.errors.principleOfCaseLaws}</p>
                  ) : null}
                </Grid>

                {/* <Grid item xs={12} md={12}>
                  <TextField
                    label="Short Paragraph"
                    color="secondary"
                    id="shortParagraph"
                    type="text"
                    key="shortParagraph"
                    rows={3}
                    value={formik.values.shortParagraph}
                    onChange={formik.handleChange}
                    multiline
                    fullWidth
                  />
                  {formik.errors.shortParagraph && formik.touched.shortParagraph ? (
                    <p style={{ color: 'red', fontSize: 12 }}>{formik.errors.shortParagraph}</p>
                  ) : null}
                </Grid> */}
                <Grid item xs={12} md={12}>
                  <p>Short Paragraph</p>
                  <TextEditor value={formik.values.shortParagraph} editorRef={shortRef} />
                </Grid>
                <Grid item xs={12} md={12}>
                  <p>Long Paragraph</p>
                  <TextEditor value={formik.values.file} editorRef={editorRef} />
                </Grid>
                <Grid item container xs={12} md={12} direction="row" justifyContent="center" alignItems="center">
                  <LoadingButton size="medium" type="submit" variant="contained" loading={isSubmitting}>
                    Update
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          </Box>
        </CardContent>
      </Card>
      <ToastContainer />
    </Container>
  );
};

export default EditCase;
