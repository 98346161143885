import DeleteIcon from '@mui/icons-material/Delete';
import Alert from '@mui/material/Alert';
import { toast, ToastContainer } from 'react-toastify';
import { LoadingButton } from '@mui/lab';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';

import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import React, { useRef, useEffect, useState } from 'react';
import FileBase64 from 'react-file-base64';
import { useNavigate, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import environment from '../../environment/env';
import StatuteService from '../../services/StatuteService';
import TextEditor from 'src/components/TextEditor';

const EditStatute = () => {
  const navigate = useNavigate();
  const { _editStatute, _getStatuteById } = StatuteService;
  const uploader = useRef();
  useEffect(() => {
    getStatute(id);
    // console("sai to hy " , id)
  }, []);
  const { fileURL } = environment;
  const { state } = useLocation();
  const { id } = state;
  // console("sai to hy " , id)
  const allowedFormates = ['pdf'];
  const editorRef = useRef(null);
  const [setFile, setFileError] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const notify = (message, type) =>
    toast(message, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      type,
    });
  const formik = useFormik({
    initialValues: {
      law_or_statute: '',
      // chapter: '',
      // section: '',
      // textSearch1: '',
      // textSearch2: '',
      file: '',
    },
    validationSchema: yup.object({
      law_or_statute: yup.string().required('Law or Statute is required'),
      file: yup.string().required('File is required'),
    }),

    onSubmit: (values) => {
      setFileError('');
      setIsSubmitting(true);

      console.log(values);
      if (!formik.isValid) {
        console.log(values);
      }
      if (!values.file) {
        setFileError('Please select a file');

        return;
      }

      // if (!values.file.includes('pdf')) {
      //   setFileError('Please attach a pdf file');
      //   notify('Please attach a PDF file', 'warning');

      //   return;
      // }
      const formData = new FormData();
      formData.append('law_or_statute', values.law_or_statute);
      formData.append('file', values.file);
      // console.log('aa gyi values', values);
      // console.log()

      _editStatute(formData, id)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            notify('Statute updated successfully', 'success');

            setTimeout(() => {
              navigate('/statutes');
            }, 2000);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(setIsSubmitting(false));
    },
  });

  const getStatute = (id) => {
    _getStatuteById(id)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          formik.setValues(res.data.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);

        notify(err?.message, 'error');
      });
  };
  return (
    <Container>
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Typography sx={{ fontSize: 24, fontWeight: 'bold' }} color="text.primary" gutterBottom>
            Edit Statute{' '}
          </Typography>

          <Box sx={{ flexGrow: 1 }}>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Law/Statute"
                    color="secondary"
                    id="law_or_statute"
                    type="text"
                    key="law_or_statute"
                    value={formik.values.law_or_statute}
                    onChange={formik.handleChange}
                    fullWidth
                  />
                  {formik.errors.law_or_statute && formik.touched.law_or_statute ? (
                    <p style={{ color: 'red', fontSize: 12 }}>{formik.errors.law_or_statute}</p>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={12}>
                  <input
                    type="file"
                    onChange={(e) => {
                      formik.setFieldValue('file', e.target.files[0]);
                    }}
                    ref={uploader}
                  />
                  {formik.errors.file && formik.touched.file ? (
                    <p style={{ color: 'red', fontSize: 12 }}>{formik.errors.file}</p>
                  ) : null}{' '}
                </Grid>
                {/* <Grid item xs={12} md={12}>
                  <TextEditor editorRef={editorRef} />
                </Grid> */}
                <Grid item container xs={12} md={12} direction="row" justifyContent="center" alignItems="center">
                  <LoadingButton size="medium" type="submit" variant="contained" loading={isSubmitting}>
                    Submit
                  </LoadingButton>
                </Grid>
                ;
              </Grid>
            </form>
          </Box>
        </CardContent>
      </Card>
      <ToastContainer />
    </Container>
  );
};

export default EditStatute;
