const baseURL = 'https://api.taxpress.com.pk/api';
const fileURL = 'https://api.taxpress.com.pk/';
//const baseURL = 'http://195.35.11.114:4000/api'
//const fileURL = 'http://195.35.11.114:4000';

const environment = {
  baseURL,
  fileURL,
};
export default environment;
