// routes
import { Navigate, Route, Routes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Layout from './Layout';
// theme
import ThemeProvider from './theme';
// components
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import ScrollToTop from './components/ScrollToTop';
import DashboardLayout from './layouts/dashboard';
import NoAuth from './NoAuth';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import PersistLogin from './PersistLogin';
import RequireAuth from './RequireAuth';
import { protectedRoutes } from './RoutesPreUser';
import useAuth from './hooks/useAuth';
import { useEffect } from 'react';

// ----------------------------------------------------------------------

export default function App() {
  const { user } = useAuth();
  useEffect(() => {
    console.log(user.user_role);
  }, [user]);
  return (
    <ThemeProvider>
      <ScrollToTop />
      <BaseOptionChartStyle />

      <Routes>
        <Route element={<Layout />}>
          <Route element={<PersistLogin />}>
            <Route element={<NoAuth />}>
              <Route path="/login" element={<Login />} />
            </Route>
            <Route element={<RequireAuth />}>
              <Route element={<DashboardLayout />}>
                {protectedRoutes
                  .find((e) => e.user === 'admin')
                  .routes.map((route) => (
                    <Route key={route.path} path={route.path} element={route.element} />
                  ))}
              </Route>
            </Route>
            <Route path="/404" element={<NotFound />} />
            {/* <Route path="*" element={<Navigate to="/404" />} /> */}
            <Route path="/" element={<Navigate to="/dashboard" />} />
          </Route>
        </Route>
      </Routes>
    </ThemeProvider>
  );
}
