import {
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import TableHead from '@mui/material/TableHead';
import { filter } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { Link as RouterLink } from 'react-router-dom';
import USERLIST from '../../_mock/user';

import environment from '../../environment/env';

import { _getUserNtnFile, _getUserNtnFileCsv, _uploadFile } from 'src/services/NtnRecordService';
import Iconify from 'src/components/Iconify';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  console.log(array);
  if (!array.length) {
    return [];
  }
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const NtnUserFiles = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [cases, setCases] = useState([]);
  const [filteredCases, setFilteredCases] = useState([]);
  const [isCaseNotFound, setisCaseNotFound] = useState([]);

  const { fileURL } = environment;

  const notify = (message, type) =>
    toast(message, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      type,
    });
  const [alert, setAlert] = useState({
    open: false,
    message: '',
  });
  useEffect(() => {
    getRoles();
  }, []);

  const getRoles = () => {
    _getUserNtnFile(id)
      .then((res) => {
        if (res.status === 200) {
          setCases(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);

        notify(err?.message, 'error');
      });
  };
  useEffect(() => {
    console.log(cases);
    const arr = applySortFilter(cases, getComparator('asc', 'name'), filterName);
    console.log(arr);
    if (arr.length === 0) {
      setisCaseNotFound(true);
      setFilteredCases([]);
    } else {
      setisCaseNotFound(false);

      setFilteredCases(arr);
    }
  }, [cases]);

  const [page, setPage] = useState(0);

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(12);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const isUserNotFound = filteredCases.length === 0;

  function downloadAll(files) {
    if (files.length == 0) return;
    const file = files.pop();
    var theAnchor = document.createElement('a');
    theAnchor.href = file;
    theAnchor.target = '_blank';
    let temp = file.split('/');
    theAnchor.download = temp[temp.length - 1];
    console.log(theAnchor);
    theAnchor.click();
    theAnchor.remove();
    downloadAll(files);
  }

  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Users & their NTN
          </Typography>
          <div
            style={{
              display: 'flex',
              gap: '5px',
            }}
          >
            <Button variant="contained" startIcon={<Iconify icon="ic:outline-refresh" />} onClick={getRoles}>
              Refresh
            </Button>
            <Button
              variant="contained"
              onClick={async () => {
                try {
                  const response = await _getUserNtnFileCsv(id);
                  const url = window.URL.createObjectURL(new Blob([response.data]));
                  console.log(response.headers);
                  const filename =
                    response.headers['content-disposition'] || `taxpress-${new Date().toDateString()}.xlsx`;

                  const a = document.createElement('a');
                  a.href = url;
                  a.download = filename; // Set the desired filename
                  document.body.appendChild(a);
                  a.click();
                  window.URL.revokeObjectURL(url);
                } catch (e) {
                  console.log(e);
                }
              }}
            >
              Generate Csv
            </Button>
          </div>
        </Stack>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell align="left">Title</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="left">Ntn Details</TableCell>
                    <TableCell align="left">Submit File</TableCell>
                    <TableCell align="left">Response File</TableCell>
                    <TableCell align="left">Detail Submit Time</TableCell>
                    <TableCell align="left">Response File Time</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredCases.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                    console.log(row);
                    const {
                      id,
                      detailSubmitTime,
                      ntnDetails,
                      ntnTitle,
                      status,
                      responseFile,
                      responseFileTime,
                      submitFile,
                    } = row;

                    return (
                      <TableRow hover key={id}>
                        <TableCell component="th" scope="row">
                          {id}
                        </TableCell>
                        <TableCell align="left">{ntnTitle}</TableCell>
                        <TableCell align="left">{status ? 'Paid' : 'Pending'}</TableCell>
                        <TableCell align="left">
                          {Object.keys(JSON.parse(ntnDetails)).map((x) => {
                            try {
                              const temp = JSON.parse(ntnDetails)[x];
                              return (
                                <p>
                                  <b>{x?.toUpperCase()}</b>:{' '}
                                  {typeof temp == 'object' && !Array.isArray(temp) ? (
                                    Object.keys(temp).map((y) => (
                                      <p style={{ margin: '0 0 0px 15px' }}>
                                        <b>{y?.toUpperCase()}</b>:{temp[y]}
                                      </p>
                                    ))
                                  ) : Array.isArray(temp) ? (
                                    <>
                                      {temp.map((x) =>
                                        Object.keys(x).map((y) => (
                                          <p style={{ margin: '0 0 0px 15px' }}>
                                            <b>{y?.toUpperCase()}</b>:{x[y]}
                                          </p>
                                        ))
                                      )}
                                    </>
                                  ) : (
                                    <>{temp}</>
                                  )}
                                </p>
                              );
                            } catch (e) {
                              return <b>Invalid data format</b>;
                            }
                          })}
                        </TableCell>
                        <TableCell align="left">
                          {submitFile ? (
                            <Button
                              variant="contained"
                              onClick={() => {
                                downloadAll(submitFile.split(',').map((i) => `${fileURL}${i}`));
                              }}
                            >
                              Download
                            </Button>
                          ) : (
                            '------'
                          )}
                        </TableCell>
                        <TableCell align="left">
                          <div
                            style={{
                              display: 'flex',
                              gap: '1em',
                            }}
                          >
                            <Button disableElevation variant="contained" component="label">
                              Upload
                              <input
                                id="fileSetting"
                                type="file"
                                hidden
                                onChange={(e) => {
                                  const formData = new FormData();
                                  formData.append('file', e.target.files[0]);
                                  console.log(id, detailSubmitTime);
                                  _uploadFile(id, formData)
                                    .then((res) => {
                                      notify(res.data.data, 'success');
                                      getRoles();
                                    })
                                    .catch((err) => {
                                      err?.response?.data?.message
                                        ? notify(err?.response?.data?.message, 'error')
                                        : notify(err?.message, 'error');
                                    });
                                }}
                              />
                            </Button>
                            {responseFile && (
                              <Button
                                variant="contained"
                                onClick={() => {
                                  downloadAll(responseFile.split(',').map((i) => `${fileURL}${i}`));
                                }}
                              >
                                Download
                              </Button>
                            )}
                          </div>
                        </TableCell>
                        <TableCell align="left">{new Date(detailSubmitTime).toLocaleString()}</TableCell>
                        <TableCell align="left">
                          {responseFileTime ? new Date(responseFileTime).toLocaleString() : '------'}
                        </TableCell>
                        {/* <TableCell align="right">
                          <ItemMenu
                            id={id}
                            icon="ic:baseline-system-update-alt"
                            menuTile="Upload Response File"
                            onClickHandler={() => {
                              navigate(`/ntn-record/${id}/files`);
                            }}
                          />
                        </TableCell> */}
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={cases.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        {alert
          ? [
              <Snackbar
                open={alert.open}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                TransitionComponent="SlideTransition"
                onClose={() => {
                  setAlert({
                    open: false,
                    message: '',
                  });
                }}
                key="Snackbar"
              >
                <Alert
                  onClose={() => {
                    setAlert({
                      open: false,
                      message: '',
                    });
                  }}
                  severity={alert.severity}
                  sx={{ width: '100%' }}
                  key="alert"
                >
                  {alert.message}
                </Alert>
              </Snackbar>,
            ]
          : null}
      </Container>
      <ToastContainer />
    </Page>
  );
};

export default NtnUserFiles;
