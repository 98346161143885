import { _put, _get, _getBlob } from './httpService';

const _uploadFile = (id, uploadFile) => {
  return _put(`/user/ntn/update/${id}`, uploadFile);
};
const _getAllNTNUsers = () => _get('/userCreation/client/ntn');
const _getUserNtnFile = (id) => _get(`/user/ntn/${id}`);
const _getUserNtnFileCsv = (id) => _getBlob(`/user/ntn/${id}/csv`);

export { _getAllNTNUsers, _uploadFile, _getUserNtnFile, _getUserNtnFileCsv };
